<template>
  <v-card flat class="workweek-settings overflow-y-auto" max-height="82vh">
    <v-layout>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="openDialogHandler"> Create New </v-btn>
    </v-layout>
    <v-container fluid>
      <v-row>
        <v-col cols="12" v-for="(workweek, workWeekIndex) in workweeks" :key="workWeekIndex">
          <v-card outlined>
            <v-card-title class="pt-3 pb-1 px-5">
              <v-text-field label="Title" v-model="modelObj.title" dense hide-details outlined style="max-width: 55%" v-if="workweek.isEditing" rows="2"></v-text-field>
              <span v-else class="body-1 font-weight-bold py-1"> {{ workweek.title }} </span>
              <v-spacer></v-spacer>
              <template v-if="workweek.isEditing">
                <v-btn small icon><v-icon color="success" @click="saveUpdateHandler()">mdi-check</v-icon></v-btn>
                <v-btn icon color="primary" @click="editWorkWeek(workWeekIndex)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn icon color="primary" @click="editWorkWeek(workWeekIndex)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="!workweek.isdefault" small icon @click="deleteHandler(workweek._id)">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-card-title>
            <v-card-text>
              <div class="py-2">
                <div class="subtitle-2 font-weight-medium"> Description </div>
                <v-textarea v-model="modelObj.description" dense hide-details outlined style="max-width: 55%" v-if="workweek.isEditing" rows="2"></v-textarea>
                <div class="body-2 grey--text text--darken-3" v-else> {{ workweek.description }} </div>
              </div>
              <div class="py-2">
                <div class="subtitle-2 font-weight-medium">
                  <v-autocomplete v-if="workweek.isEditing && !workweek.isdefault" style="max-width: 55%" v-model="modelObj.applicableto" :items="modelObj.notifyToItems" chips :search-input.sync="modelObj.notifyToSearch" label="Applicable To"
                    item-text="name" multiple dense hide-details outlined clearable return-object>
                    <template #selection="data">
                      <v-chip small outlined color="primary" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                        <v-avatar size="10" left> <v-img src="https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png"></v-img> </v-avatar> {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs" :class="['body-2', 'text-capitalize']">
                        <v-list-item-avatar :size="23">
                          <img :src="item.avatar">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          {{ (item.name === 'header') ? $t(item.name) : item.name }} ( {{$_getRecordType(item.model)}} )
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <template v-else>
                    <span>Applicable to : </span>
                    <span v-if="workweek.applicableto && workweek.applicableto.length">
                      <v-chip v-for="(item, applicableIndex) in workweek.applicableto" :key="applicableIndex" small outlined color="primary">
                        <!-- <v-avatar size="10" left> 
                          <v-img src="https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png"></v-img> 
                        </v-avatar>  -->
                        {{ item.name }}
                      </v-chip>
                    </span>
                    <span v-else class="body-2 grey--text text--darken-3"> All </span>
                  </template>
                </div>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <div class="py-5 px-4">
                <div>
                  <v-icon color="primary" size="18"> mdi-alert-box </v-icon>
                  <span style="font-size: 0.75rem;" class="px-1 font-weight-medium text--darken-4"> click on the weekday to change </span>
                </div>
                <table class="workweek-table">
                  <thead>
                    <tr>
                      <th> Week </th>
                      <th> Mon </th>
                      <th> Tue </th>
                      <th> Wed </th>
                      <th> Thu </th>
                      <th> Fri </th>
                      <th> Sat </th>
                      <th> Sun </th>
                    </tr>
                  </thead>
                  <tbody :key="workweek._id">
                    <tr v-for="(weekItem, weekItemIndex) in workweek.ruleconfig" :key="weekItemIndex">
                      <td class="font-weight-bold"> {{ weekItemIndex + 1 }} </td>
                      <template v-for="(weekDayItem, weekDayItemIndex) in weekItem">
                        <v-menu :key="weekDayItemIndex" offset-x nudge-right="-10">
                          <template #activator="{ on, attrs }">
                            <td v-on="on" v-bind="attrs">
                              <v-icon :color="weekItems[weekDayItem].color" size="20"> mdi-checkbox-blank </v-icon>
                            </td>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="(item, index) in weekItems" :key="index"
                            @click="updateWeekDayRule({ workWeekIndex, weekItemIndex, weekDayItemIndex, value: index })">
                              <v-icon :color="item.color" size="20"> mdi-checkbox-blank </v-icon>
                              <span class="caption font-weight-medium mx-2"> {{ item.label }} </span>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-text>
            <v-card-actions class="px-2 pb-3">
              <div class="d-inline-flex align-center px-2" v-for="(weekDayItem, index) in weekItems" :key="index">
                <v-icon :color="weekDayItem.color" size="20"> mdi-checkbox-blank </v-icon>
                <span class="caption font-weight-medium mx-1"> {{ weekDayItem.label }} </span>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card elevation="0" height="100%">
        <v-card-title class="primary darken-3 white--text px-4">
          <span class="body-1 font-weight-medium"> Create Workweek </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Title" v-model="modelObj.title" :rules="setValidation('requiredValidation')"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Description" v-model="modelObj.description"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateHandler"> Save </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset()" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      notifyToItems: [],
      notifyToSearch: '',
      dialog: false,
      workweeks: [],
      modelObj: {
        applicableto: [],
        ruleconfig: [
          [1, 1, 1, 1, 1, 0, 0],
          [1, 1, 1, 1, 1, 2, 0],
          [1, 1, 1, 1, 1, 0, 0],
          [1, 1, 1, 1, 1, 2, 0],
          [1, 1, 1, 1, 1, 0, 0]
        ]
      }
    }
  },
  beforeMount () {
    this.getListHandler()
  },
  computed: {
    weekItems () {
      return [{
        label: 'Week off',
        color: 'error darken-1'
        }, {
        label: 'Working day',
        color: 'success darken-1'
      }, {
        label: 'Half day',
        color: 'orange darken-1'
      }]
    }
  },
  watch: {
    'modelObj.notifyToSearch' (value) {
      if (!value) return this.modelObj.notifyToItems = [...this.modelObj.applicableto]
      this.$_execute('get', `find?name=${value || ''}`).then(({ data }) => {
        data.forEach((item) => {
          item.avatar = 'https://cdn.vuetifyjs.com/images/lists/1.jpg'
          item.name = item.model === 'classes' ? `${ item.name }${ item.section ? ` - ${ item.section }` : '' }` : item.name
        })
        if (!this.modelObj.applicableto) this.modelObj.applicableto = []
        this.modelObj.notifyToItems = [...data, ...this.modelObj.applicableto]
      })
    },
  },
  methods: {
    openDialogHandler () {
      this.modelObj = {
        ruleconfig: [
          [1, 1, 1, 1, 1, 0, 0],
          [1, 1, 1, 1, 1, 2, 0],
          [1, 1, 1, 1, 1, 0, 0],
          [1, 1, 1, 1, 1, 2, 0],
          [1, 1, 1, 1, 1, 0, 0]
        ]
      }
      this.dialog = true
    },
    saveUpdateHandler () {
      const model = this.modelObj
      const method = model._id ? 'put' : 'post'
      const url = model._id ? `workweeks/${model._id}` : 'workweeks'
      this.$_execute(method, url, model).then(() => {
        if (!model._id) {
          this.dialog = false
          this.modelObj = {}
          this.$refs.form.reset()
        }
        this.getListHandler()
      })
    },
    editWorkWeek (index) {
      this.modelObj = this.$_copy({...this.workweeks[index], notifyToItems: [], notifyToSearch: ''})
      // this.modelObj.notifyToItems = [] 
      this.workweeks[index].isEditing = this.workweeks[index].isEditing ? false : true
      this.workweeks = [...this.workweeks]
    },
    getListHandler () {
      this.$_execute('get', 'workweeks').then(({ data }) => {
        data.map((item) => { 
          if (!item.applicableto) item.applicableto = []
          item.applicableto.map((item) => {
            if (item._id.formdata && item._id.formdata.name) item.name = item._id.formdata.name
          }) 
        })
        this.workweeks = data
      })
    },
    updateWeekDayRule({ workWeekIndex, weekItemIndex, weekDayItemIndex, value }) {
      const oldvalue = this.workweeks[workWeekIndex].ruleconfig[weekItemIndex][weekDayItemIndex]
      this.workweeks[workWeekIndex].ruleconfig[weekItemIndex][weekDayItemIndex] = value
      this.$_execute('put', `workweeks/${this.workweeks[workWeekIndex]._id}?patch=true`, { ruleconfig: this.workweeks[workWeekIndex].ruleconfig })
        .then(() => {
          this.$root.$emit('snackbar', { message: 'Workweek updated', color: 'success' })
        })
        .catch(() => {
          this.workweeks[workWeekIndex].ruleconfig[weekItemIndex][weekDayItemIndex] = oldvalue
        })
        .finally(() => {
          this.$set(this.workweeks, workWeekIndex, { ...this.workweeks[workWeekIndex], key: this.workweeks[workWeekIndex].key + 1 })
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'workweeks',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
<style>
  .workweek-settings table.workweek-table thead th,
  .workweek-settings table.workweek-table tbody td {
    border: 1px solid grey;
  }
  .workweek-settings table.workweek-table thead th, .workweek-settings table.workweek-table tbody td {
    padding: 5px 30px;
    text-align: center;
  }
</style>
